.achievements-section-wrapper {
    margin-left: auto;
    margin-right: auto;
    padding: 6rem;
}

.achievements-section-wrapper>h2 {
    width: 100%;
    color: #414141;
    font-size: 3.2rem;
    text-align: center;
    text-transform: uppercase;
}

.achievements-section-body {
    padding: 0 3rem;
}

.text-wrapper>h3 {
    font-size: 2.5rem;
    text-align: center;
}

.achievements-card-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10rem;
    color: #414141;
    text-align: center;
    letter-spacing: 0px;
}

.achievements-card-wrapper>img {
    width: auto;
    max-height: 53rem;
}

.text-wrapper {
    width: 40%;
}

@media (max-width: 1200px) {
    .achievements-card-wrapper>img {
        max-height: 40rem;
    }
}

@media (max-width: 992px) {
    .achievements-section-wrapper {
        padding: 1rem;
    }

    .text-wrapper {
        width: 50%;
    }
}

@media (max-width: 768px) {
    .achievements-card-wrapper {
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 5rem;
        text-align: left;
    }

    .text-wrapper {
        width: 90%;
    }
}

@media (max-width: 440px) {
    .achievements-card-wrapper>img {
        max-height: 30rem;
    }
}